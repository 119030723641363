import React from 'react'
import { Container } from '@mythical/ui'
import { RenderBlocks } from 'app/components/blocks/RenderBlocks'
import { Meta } from 'app/components/primitive/Meta'
import Spinner from 'app/components/primitive/Spinner'
import SubscriptionPanel, {
  Perk,
} from 'app/components/primitive/SubscriptionPanel'
import MainLayout from 'app/components/structure/Layout/MainLayout'
import Section from 'app/components/structure/Section'
import useQuery from 'app/hooks/use-query'
import { createTiersQuery, mapToTiers } from 'app/queries/tiers/member-tiers'
import { isIOS } from 'app/utils/constants'
import { observer } from 'mobx-react-lite'
import { createPerksQuery, mapPerks } from 'app/queries/perks/perks'
import {
  createLandingQuery,
  mapToLandingContent,
} from 'app/queries/globals/landing'
import { PromoBanner as IPromoBannerBlock } from 'app/blocks/promo-banner'

const params = {}

const query = createTiersQuery()
const landingQuery = createLandingQuery()
const perksQuery = createPerksQuery()

export const LandingScreen = observer(() => {
  const { data } = useQuery(query, params, mapToTiers)

  const { data: pageData, loading } = useQuery(
    landingQuery,
    {},
    mapToLandingContent
  )
  const { data: perksData } = useQuery(perksQuery, params, mapPerks)

  const pageContent = pageData?.pageContent

  const promoBannerBlock = pageContent?.find(
    (block) => block.__typename === 'PromoBanner'
  ) as IPromoBannerBlock

  return (
    <MainLayout
      noSidebar
      noBottomTabs
      noFooter={false}
      contentStyles={{
        maxWidth: 1024,
        mx: 'auto',
        w: '100%',
        mb: '$5',
        minWidth: 'auto',
        borderWidth: 1,
        borderColor: '$primary10',
        bg: '$secondary10',
      }}
      promoBannerProps={promoBannerBlock}
    >
      <Meta title="Welcome" />
      {loading && !pageData && <Spinner p="$4" size="large" />}
      {pageContent && <RenderBlocks bg="$secondary9" blocks={pageContent} />}

      {!isIOS && (
        <Section bg="$secondary10" space="$3" spaciousY paddedX>
          <Container>
            <SubscriptionPanel
              perks={perksData as Perk[]}
              initialLevel={1}
              memberTiers={data}
            />
          </Container>
        </Section>
      )}
    </MainLayout>
  )
})
