import { LandingScreen } from 'app/features/landing/screen'
import { GetStaticProps } from 'next'

import { getLandingPageStaticProps } from '../lib/landing-retriever'

const Homepage = () => <LandingScreen />

Homepage.displayName = 'LandingScreen'

export default Homepage

export const getStaticProps: GetStaticProps = async (context) => {
  return getLandingPageStaticProps(context)
}
