import {
  LandingContentBlock,
  landingContentBlocks,
} from 'app/blocks'
import { gql } from 'graphql-request'

export interface LandingContent {
  pageContent: LandingContentBlock[]
}

export const createLandingQuery = () => gql`
  ${landingContentBlocks}
  query getLanding {
    LandingPage {
      pageContent {
        __typename
        ...landingAllSeriesFragment
        ...landingHeroFragment
        ...bannerFragment
        ...landingSingleSeriesFragment
        ...landingCollectiblesFragment
        ...promoBanner
        ...quizFragment
      }
    }
  }
`

export const mapToLandingContent = (data: any): LandingContent => {
  return {
    pageContent: data.LandingPage.pageContent as LandingContentBlock[],
  }
}
